import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import store, { RootState } from '@/store'
import axios from 'axios'
import { Data } from '@/modules/common/apiConfig'
import { LifestyleRecommendation } from '@/modules/items/types/itemTypes'
import { set } from 'lodash-es'
import { ResultDocumentData } from '@/modules/items/types/resultsTypes'
import {
	Announcement,
	AnnouncementAttributes,
	AnyUser,
	ClientType,
	UserType,
} from '@/modules/auth/types/authTypes'
import { authTestingPanelMutations } from '@/modules/testing/mutations/authTestingMutations'

export type State = {
	isLoggedIn: boolean
	token: string
	disableTrackEvents: boolean
	user: UserType | AnyUser
	announcements: Announcement[]
}

export const state = (): State => ({
	isLoggedIn: false,
	token: '',
	disableTrackEvents: false,
	user: {},
	announcements: [],
})

const mutations: MutationTree<State> = {
	setLoggedIn(state: State, isLoggedIn: boolean) {
		state.isLoggedIn = isLoggedIn
	},
	setToken(state: State, token: string) {
		state.token = token
	},
	setDisableSegmentTracking(state: State, disable: boolean) {
		state.disableTrackEvents = disable
	},
	setUser(state: State, user: UserType) {
		if (state.user !== null) {
			state.user = {
				...state.user,
				...user,
			}
		} else {
			state.user = user
		}
	},
	setClient(state: State, client: ClientType) {
		if (!state.user?.client) {
			state.user.client = client
		} else {
			state.user.client = {
				...state.user.client,
				...client,
			}
		}
	},
	setClientLatestReadyResult(state: State, result: ResultDocumentData) {
		if (!state.user.client) {
			return
		}

		state.user.client.relationships.latest_ready_result = result?.attributes || result
	},
	setAnnouncements(state: State, announcements: Announcement[]) {
		state.announcements = announcements
	},
	dismissAnnouncement(state: State, announcement: Announcement) {
		announcement.attributes.is_dismissed = true
	},
	setLifestyleRecommendations(state: State, lifestyleRecommendations: LifestyleRecommendation) {
		set(state, 'user.client.relationships.lifestyleRecommendations', lifestyleRecommendations || [])
	},
	...authTestingPanelMutations,
}

const actions: ActionTree<State, RootState> = {
	async login({ commit, dispatch }, requestData): Promise<any> {
		const { data } = await axios.post('/login', requestData)
		commit('setToken', data.token)
		commit('setUser', data.user)
		if (!data.user.google2fa_configured_at) {
			commit('setLoggedIn', true)
			await dispatch('getProfile')
		}

		await dispatch('splitIO/setupClient')
		return data
	},
	async loginWithToken(
		{ commit, dispatch, rootState },
		{ token, redirect }: { token: string; redirect: boolean },
	): Promise<any> {
		commit('setToken', token)
		commit('setLoggedIn', true)
		commit('setDisableSegmentTracking', true)
		await dispatch('getProfile')
		await dispatch('splitIO/setupClient')
	},
	async login2FA({ dispatch, commit }, one_time_password) {
		await axios.post('/login-2fa', {
			one_time_password,
		})
		commit('setLoggedIn', true)
		await dispatch('getProfile')
	},
	async logout({ commit }): Promise<any> {
		commit('setToken', null)
		commit('setUser', {})
		commit('setLoggedIn', false)

		localStorage.removeItem('vuex')
		store.reset()
	},
	async forgotPassword({ commit }, requestData): Promise<any> {
		return axios.post('/forgot-password', requestData)
	},
	async resetPassword({ commit }, requestData): Promise<any> {
		return axios.post('/reset-password', requestData)
	},
	async getProfile(
		{ commit, state },
		params = { related: 'client.latestReadyResult' },
	): Promise<any> {
		const { data } = await axios.get('/restify/profile', {
			params,
		})
		const userData = {
			...data.attributes,
			...data.relationships,
		}

		commit('setLoggedIn', true)
		commit('setUser', userData)
		commit(
			'setClientLatestReadyResult',
			data.relationships?.client.relationships?.latestReadyResult ||
				data.attributes.parent_user?.client.latestReadyResult,
		)

		if (state.disableTrackEvents) {
			return
		}

		window.analytics?.identify(data.relationships?.client?.attributes?.number)
	},
	async updateProfile({ commit, dispatch }, requestData): Promise<any> {
		const { data } = await axios.post('/restify/profile', requestData)
		commit('setUser', data.user)
		await dispatch('getProfile')
	},
	async updateClient({ commit, state, dispatch }, requestData): Promise<any> {
		const clientId = state.user?.client?.attributes?.id
		const { data } = await axios.put(`/restify/clients/${clientId}`, requestData)
		commit('setClient', data)
	},
	async enable2FA({ dispatch, state }): Promise<any> {
		await axios.post('/restify/users/actions?action=enable-two-factor-auth', {
			secret: state.user?.google_authentication?.code,
		})
		await dispatch('getProfile')
	},
	async disable2FA({ dispatch }): Promise<any> {
		await axios.post('/restify/users/actions?action=disable-two-factor-auth')
		await dispatch('getProfile')
	},
	async getAnnouncements({ commit, state }, params = {}): Promise<any> {
		params.active = true

		const { data } = await axios.get('/restify/announcements', {
			params,
		})

		data.forEach((announcement: Announcement) => {
			const existingAnnouncement = state.announcements.find(
				(announce: Announcement) => announce.id === announcement.id,
			)

			if (existingAnnouncement) {
				announcement.attributes.is_dismissed = existingAnnouncement.attributes.is_dismissed
			} else {
				announcement.attributes.is_dismissed = false
			}
		})

		commit('setAnnouncements', data)
	},
	async getLifestyleRecommendations({ commit }): Promise<any> {
		const params = {
			sort: 'sort_order',
		}

		const recommendations = (
			await axios.get('/restify/recommendations', {
				params,
			})
		)?.data

		commit('setLifestyleRecommendations', recommendations)
	},
}

const getters: GetterTree<State, RootState> = {
	undismissedAnnouncements:
		(state: State) =>
		(location: string): Announcement | undefined => {
			return state.announcements.find((announcement: Data<AnnouncementAttributes>) => {
				return (
					announcement?.attributes?.location === location && !announcement?.attributes?.is_dismissed
				)
			})
		},
	isUnmarketable(state: State) {
		return typeof state.user.meta?.flag_unmarketable !== 'undefined'
			? state.user.meta?.flag_unmarketable
			: false
	},
}

const module: Module<State, RootState> = {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}

export default module

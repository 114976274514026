export enum Steps {
	ConfirmationStep = 'ConfirmationStep',
	VideoWalkthroughStep = 'VideoWalkthroughStep',
	CollectionInformationStep = 'CollectionInformationStep',
	FillCupStep = 'FillCupStep',
	AddTransportMediaStep = 'AddTransportMediaStep',
	ApplyShippingLabelStep = 'ApplyShippingLabelStep',
	SealShippingBoxStep = 'SealShippingBoxStep',
	SchedulePickupStep = 'SchedulePickupStep',
	FinishedStep = 'FinishedStep',
	NotStarted = 'NotStarted',
}

export enum DeliveryCarriers {
	UPS = 'UPS',
	FedEx = 'FedEx',
}

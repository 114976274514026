<template>
	<template v-if="maintenanceMode">
		<div>Sorry, we are currently down for maintenance. Please check back soon.</div>
	</template>
	<template v-else>
		<div
			v-if="!isOnline"
			class="fixed bottom-0 text-center w-full text-white bg-evergreen-800 z-50 p-2"
		>
			{{ $t('You are offline...') }}
		</div>
		<div class="font-grotesque">
			<notifications />
			<router-view />
		</div>
	</template>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { DEFAULT_APP_TITLE } from '@/modules/common/config'
import Navbar from '@/components/common/navbar/Navbar.vue'
import { isAppleDevice } from '@/modules/common/navigatorUtils'
import { useOnline } from '@vueuse/core'

export default defineComponent({
	components: { Navbar },
	data() {
		return {
			maintenanceMode: import.meta.env.VITE_MAINTENANCE_MODE === 'true',
		}
	},
	setup() {
		const isOnline = useOnline()

		return {
			isOnline,
		}
	},
	methods: {
		checkNonAppleDevice() {
			if (isAppleDevice()) {
				return
			}
			document.documentElement.classList.add('non-apple-device')
		},
		setTitle() {
			let routeName = this.$route.name ? String(this.$route.name) : ''
			if (routeName) {
				document.title = `${routeName} - ${DEFAULT_APP_TITLE}`
			} else {
				document.title = DEFAULT_APP_TITLE
			}
		},
		async tryTokenLogin() {
			const { loginToken } = this.$route.query
			if (!loginToken) {
				return
			}

			const query = { ...this.$route.query }
			delete query.loginToken

			this.$router.replace({ query })
			await this.$store.dispatch('auth/loginWithToken', { token: loginToken, redirect: true })
			await this.$router.push('/home')
		},
		async setupSplitIO() {
			await this.$store.dispatch('splitIO/setupClient')
		},
		destroySplitIO() {
			this.$store.dispatch('splitIO/destroyClient')
		},
		loadScripts(url: string, async: boolean, defer: boolean, onResolve = () => {}): void {
			const script = document.createElement('script')
			script.src = url
			script.async = async
			script.defer = defer
			script.onload = () => onResolve()

			document.body.appendChild(script)
		},
	},
	watch: {
		$route: {
			async handler() {
				this.setTitle()

				await this.tryTokenLogin()

				if (this.$isLoggedIn) {
					this.$store.dispatch('auth/getAnnouncements')
				}
			},
			immediate: true,
		},
	},
	async mounted() {
		this.checkNonAppleDevice()
		this.setupSplitIO()
	},
	created() {
		window.SegmentKey = import.meta.env.VITE_SEGMENT_KEY
		setTimeout(() => {
			this.loadScripts('https://www.googleoptimize.com/optimize.js?id=OPT-5RTZWXC', true, false)
			this.loadScripts(
				'https://www.googletagmanager.com/gtag/js?id=G-RP6EDKZ598',
				true,
				true,
				() => {
					if (!/HeadlessChrome/.test(window.navigator.userAgent) && !window.navigator.webdriver) {
						this.loadScripts('/gta.js', true, true)
					}
				},
			)
		}, 3500)
	},
	unmounted() {
		this.destroySplitIO()
	},
})
</script>

import { createApp } from 'vue'
// @ts-ignore
import App from './App.vue'
import router from './router/router'
import i18n from '@/i18n'
import store from '@/store'
import globalPlugins from '@/plugins/globalPlugins'
import { sync } from 'vuex-router-sync'
import pwa from '@/plugins/pwa'
import '@/assets/css/index.scss'
// @ts-ignore
import 'virtual:svg-icons-register'
import './polyfills'

sync(store, router)

const app = createApp(App).use(store).use(i18n).use(router).use(globalPlugins).use(pwa)

app.mount('#app')

import './modules/common/apiConfig'

import { Module, Store } from 'vuex'
import { createStore } from 'vuex-extensions'
import createPersistedState from 'vuex-persistedstate'
import { State as AuthState } from '@/modules/auth/store/authModule'
import { State as AuthorizedUsersState } from '@/modules/authorizedUsers/store/authorizedUsersModule'
import { State as DocumentsState } from '@/modules/documents/store/documentsModule'
import { State as CryogenicsState } from '@/modules/cryogenics/store/cryogenicsModule'
import { State as ItemsState } from '@/modules/items/store/itemsModule'
import { State as OrdersState } from '@/modules/orders/store/ordersModule'
import { State as KitRegistrationsState } from '@/modules/kitRegistrations/store/kitRegistrationsModule'
import { State as PaymentsState } from '@/modules/payments/store/paymentsModule'
import { State as UrologistState } from '@/modules/urologists/store/urologistsModule'
import { State as CheckoutState } from '@/modules/checkout/store/checkoutModule'
import { State as TelehealthState } from '@/modules/telehealth/store/telehealthModule'
import { State as SplitIOState } from '@/modules/splitio/store/splitIOModule'
import { State as BenefitsProviderState } from '@/modules/benefitsProviders/store/benefitsProvidersModule'
import { RouteLocationNormalizedLoaded } from 'vue-router'

export type RootState = {
	auth: AuthState
	authorizedUsers: AuthorizedUsersState
	documents: DocumentsState
	cryogenics: CryogenicsState
	items: ItemsState
	kitRegistrations: KitRegistrationsState
	orders: OrdersState
	payments: PaymentsState
	urologists: UrologistState
	checkout: CheckoutState
	telehealth: TelehealthState
	route: RouteLocationNormalizedLoaded
	splitio: SplitIOState
	benefitsProviders: BenefitsProviderState
}

interface Modules {
	[key: string]: Module<object, RootState>
}

function getStoreModules() {
	const files = import.meta.globEager('./modules/**/store/*Module.ts')
	const fileKeys: Array<string> = Object.keys(files)
	const modules: Modules = {}
	fileKeys.forEach(key => {
		if (!files[key]) {
			return
		}

		const storeModule = files[key].default
		const pathParts: Array<string> = key.split('/')
		const fileName: string = pathParts[pathParts.length - 1]
		const moduleName: string = fileName.replace('Module.ts', '')
		if (storeModule) {
			modules[moduleName] = storeModule
		}
	})
	return modules
}

export default createStore(Store, {
	plugins: [
		createPersistedState({
			paths: ['auth'],
		}),
		// createPersistedState({
		//   paths: ['checkout'],
		//   storage: window.sessionStorage
		// })
	],
	modules: {
		...getStoreModules(),
	},
})

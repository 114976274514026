import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios'
import { API_URL } from '@/modules/common/config'
import { notifications, NotificationType, notify } from '@/components/common/NotificationPlugin'
import {
	isForbidden,
	isInternalServerError,
	isPreCondition,
	isUnauthorized,
	mapErrors,
} from '@/modules/common/utils/requestUtils'
import store from '@/store'
import router from '@/router/router'

export interface Meta {
	current_page: number
	from: number
	last_page?: number
	path?: string
	per_page?: number
	to: number
	total?: number
}

export interface Links {
	first: string
	last: string
	prev: string | null
	next: string | null
	filters: string
}
export interface AttributeMeta {
	authorizedToShow: boolean
	authorizedToStore: boolean
	authorizedToUpdate: boolean
	authorizedToDelete: boolean
}

export interface Data<T> {
	id: string
	type: string
	attributes: T
	relationships: Record<any, any> | null
	meta: AttributeMeta
}

export interface DataList<T> {
	data: Array<Data<T>>
}

export interface AxiosList<T> {
	meta: Meta
	links: Links
	data: Array<Data<T>>
}

export interface RequestParams {
	page?: number
	perPage?: number
	related?: string
	sort?: string
	[key: string]: any
}

export const statusCodesToHandle = [400, 401, 422]

axios.defaults.baseURL = String(API_URL)

export function requestInterceptor(config: AxiosRequestConfig): AxiosRequestConfig {
	const token = store.state.auth.token

	if (!config.headers) {
		config.headers = {}
	}

	config.headers.Accept = 'application/vnd.api+json'

	if (!config.headers['Authorization'] && token) {
		config.headers['Authorization'] = `Bearer ${token}`
	}

	// if (!config.headers['Split-User']) {
	// 	config.headers['Split-User'] =
	// 		new URL(window.location.href.toLowerCase()).searchParams.get('split_user') ??
	// 		window.analytics?.user()?.id()
	// }
	return config
}

axios.interceptors.request.use(requestInterceptor)

export function successInterceptor(response: AxiosResponse): AxiosResponse {
	return response.data
}

export interface CustomAxiosError extends AxiosError {
	handled: boolean
	errors: any
	status: number
}

export async function errorInterceptor(error: CustomAxiosError) {
	// Happens for cancelled requests using axios CancelTokenSource
	if (!error.response) {
		return Promise.reject(error)
	}

	const { status } = error.response
	let errors = ''

	if (statusCodesToHandle.includes(status)) {
		errors = mapErrors(error.response.data)
		if (errors === 'Unauthenticated.') {
			errors = 'Your session expired. Please login in again to use the application'
			await store.dispatch('auth/logout')
			await router.push('/login')
		}
		if (notifications.state.length === 0) {
			notify({
				type: NotificationType.Error,
				message: errors,
				once: true,
			})
			error.handled = true
		}
	}

	if (isPreCondition(status)) {
		errors = 'One time password confirmation is required in order to perform this action'
		if (notifications.state.length === 0) {
			notify({
				type: NotificationType.Error,
				message: errors,
				once: true,
			})
			error.handled = true
		}
		await router.push('/2fa-login')
	}

	if (isForbidden(status)) {
		errors = 'You are not allowed to perform this action'
		if (notifications.state.length === 0) {
			notify({
				type: NotificationType.Error,
				message: errors,
				once: true,
			})
			error.handled = true
		}
	}

	if (isInternalServerError(status)) {
		errors = 'A server error occurred during request execution'
		if (notifications.state.length === 0) {
			notify({
				type: NotificationType.Error,
				message: errors,
				once: true,
			})
			error.handled = true
		}
	}

	error.errors = errors
	error.status = status

	if (isUnauthorized(status)) {
		// Logout here if necessary

		return Promise.reject(error)
	}
	return Promise.reject(error)
}

axios.interceptors.response.use(successInterceptor, errorInterceptor)

export function isUnauthorized(status: number): boolean {
	return status === 401
}

export function isForbidden(status: number): boolean {
	return status === 403
}

export function isPreCondition(state: number): boolean {
	return state === 428
}

export function isInternalServerError(status: number): boolean {
	return status === 500
}

export function mapErrors(data: any): string {
	let errors = ''

	/**
	 * Generic error format handling
	 * {
	 *   "message": "Some error message",
	 *   ... other keys (but no "errors" key)
	 * }
	 */
	if (!data.errors && data.message) {
		return data.message
	}
	/**
	 * JSON API error format
	 * {
	 *   "errors": [
	 *     {
	 *       code: 0,
	 *       title: "Connection could not be established",
	 *     }
	 *   ],
	 * }
	 */
	if (Array.isArray(data.errors)) {
		const hasStringErrors = typeof data.errors[0] === 'string'

		if (hasStringErrors) {
			return data.errors[0]
		}
		return data.errors.map((error: any) => error?.title).join('</br>')
	}
	/**
	 * Default Laravel Error format
	 * {
	 *   "errors": {
	 *      "foo": "The foo field is required",
	 *   },
	 *   "message": "The given data was invalid.",
	 * }
	 */
	for (let err in data.errors) {
		let errorRow = data.errors[err].join('</br>')
		if (!errorRow.endsWith('</br>')) {
			errorRow += '</br>'
		}
		errors += errorRow
	}
	return errors
}

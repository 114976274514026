import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import store, { RootState } from '@/store'
import axios from 'axios'
import { ProductsUuid } from '@/modules/checkout/enum/productsUuid'
import { cloneDeep, merge, set } from 'lodash-es'

import {
	CartItemExtended,
	CheckoutModel,
	CheckoutProductsResponse,
	CheckoutTotals,
	ClientDetails,
	FormUpdateModel,
	Insurance,
	InsuranceEligibilityModel,
	InsuranceProvider,
	Product,
	PromoCodeData,
	SessionProduct,
	UpsellProduct,
} from '@/modules/checkout/types/checkoutTypes'
import { trackCheckoutGTM, trackSegment } from '@/modules/analytics/track'
import {
	getProductsForSegment,
	getPurchasedProducts,
	segmentConversion,
} from '@/modules/checkout/helpers/checkoutUtils'
import { postImpactConversionEvent } from '@/modules/checkout/helpers/impactUtils'
import { getTimezone } from '@/modules/common/utils/dateUtils'
import { PackagesUuid } from '@/modules/checkout/enum/packagesUuid'
import { TrackingEvents } from '@/modules/common/utils/trackingEvents'
import { AnyUser, UserType } from '@/modules/auth/types/authTypes'
import { BillingType } from '@/modules/checkout/enum/checkoutEnums'
import { OrderAttributes } from '@/modules/orders/types/orderTypes'

export const packageUuids = {
	MONTHLY: 'dc7258dc-69bf-4122-ab16-97aa20ef707f',
	YEARLY: '8ed3cfcf-2cfe-4fa6-973f-107d6b6906ca',
	FIVE_YEARS: 'a07809d3-7dab-43f8-b1cb-2b1c26e7a89d',
	TEN_YEARS: '35a39b22-50f8-4891-bb27-1131fcc9a9fa',
}

export const storageKey = 'shoppingCart'

export type State = {
	products: Product[]
	upsellProducts: UpsellProduct[]
	appliedPromoCodes: PromoCodeData[]
	cart: SessionProduct[]
	checkoutModel: CheckoutModel
	currentStep: number
	isGift: boolean
	intent: Record<string, string> | null
	checkoutUser: UserType | AnyUser | null
	checkout_loading: boolean
	insurance: Insurance | null
	insuranceProviders: InsuranceProvider[]
	checkoutModelInitialising: boolean
	newPromoCode: string
	orderPlaced: OrderAttributes | null
}

const DEFAULT_CHECKOUT_MODEL = {
	shipping_address: { country: 'US', kit_delivery_type: 'staggered' },
	billing_address: { country: 'US', kit_delivery_type: 'staggered' },
	billing_address_type: BillingType.SAME_AS_SHIPPING,
} as any

export const state = (): State => ({
	products: [],
	upsellProducts: [],
	appliedPromoCodes: [],
	cart: [],
	orderPlaced: null,
	checkoutModel: DEFAULT_CHECKOUT_MODEL,
	currentStep: 0,
	isGift: false,
	intent: null,
	checkoutUser: null,
	checkout_loading: false,
	checkoutModelInitialising: true,
	insurance: null,
	insuranceProviders: [],
	newPromoCode: '',
})

const mutations: MutationTree<State> = {
	setProducts(state, products: Product[]) {
		state.products = products
	},
	setUpsellProducts(state, products: UpsellProduct[]) {
		state.upsellProducts = products
	},
	addAppliedPromoCode(state, promoCode: PromoCodeData) {
		const matchingIndex = state.appliedPromoCodes.findIndex(c => c.code === promoCode.code)
		if (matchingIndex === -1) {
			state.appliedPromoCodes.push(promoCode)
		} else {
			state.appliedPromoCodes.splice(matchingIndex, 1, promoCode)
		}
	},
	removePromoCode(state, code) {
		const index = state.appliedPromoCodes.findIndex(c => c.code === code)
		if (index > -1) {
			state.appliedPromoCodes.splice(index, 1)
		}
	},
	resetPromoCode(state) {
		state.appliedPromoCodes = []
	},
	setCart(state, data: SessionProduct[]) {
		state.cart = data
	},
	setInsurance(state, data) {
		state.insurance = data
	},
	checkoutLoading(state, value: boolean) {
		state.checkout_loading = value
	},
	addWithdrawal(state, withdrawalUuid: string) {
		state.checkoutModel.withdrawal_uuid = withdrawalUuid
	},
	addOrUpdateCartItem(state, item: SessionProduct) {
		const index = state.cart.findIndex(c => c.id === item.id)
		if (index > -1) {
			state.cart[index].count++
		} else {
			state.cart.push(item)
		}
	},
	removeCartItem(state, item: SessionProduct) {
		const index = state.cart.findIndex(c => c.id === item.id)
		if (index > -1) {
			state.cart.splice(index, 1)
		}
	},
	decrementCartItemQuantity(state, item) {
		const index = state.cart.findIndex(c => c.id === item.id)
		if (index === -1) {
			return
		}
		let currentQuantity = state.cart[index].count
		let newQuantity = currentQuantity <= 1 ? 1 : currentQuantity - 1
		state.cart[index].count = newQuantity
	},
	updateCheckoutModel(state, updateModel: FormUpdateModel) {
		let newCheckoutModel = { ...state.checkoutModel }
		set(newCheckoutModel, updateModel.key, cloneDeep(updateModel.value))
		state.checkoutModel = merge({}, state.checkoutModel, newCheckoutModel)
	},
	setPlacedOrder(state, model: OrderAttributes) {
		state.orderPlaced = model
	},
	setCheckoutModel(state, model: CheckoutModel) {
		state.checkoutModel = model
	},
	setCurrentStep(state, value: number) {
		state.currentStep = value
	},
	setIsGift(state, value: boolean) {
		state.isGift = value
	},
	setIntent(state, intent) {
		state.intent = intent
	},
	setCheckoutUser(state, user) {
		state.checkoutUser = user
	},
	setInsuranceProviders(state, value) {
		state.insuranceProviders = value
	},
	setCheckoutModelInitialising(state, value) {
		state.checkoutModelInitialising = value
	},
	setNewPromoCode(state, value) {
		state.newPromoCode = value
	},
}
const actions: ActionTree<State, RootState> = {
	async getAllProducts({ commit }) {
		const { data }: { data: CheckoutProductsResponse } = await axios.get('/checkouts')
		commit('setProducts', data.products)
	},
	async getUpsellProducts({ state, commit }) {
		if (!state.cart.length) {
			return
		}

		const { data }: { data: UpsellProduct[] } = await axios.post('/upsell', {
			items: state.cart.map((item: SessionProduct) => ({
				product_uuid: item.id,
				quantity: item.count,
			})),
		})

		commit('setUpsellProducts', data)
	},
	async getInsuranceProviders({ commit }) {
		const data = await axios.get('/insurance/providers')

		const uniqueProviders: Record<string, InsuranceProvider> = {}

		data.insuranceProviders.forEach((provider: InsuranceProvider) => {
			if (uniqueProviders[provider.provider_name]) {
				uniqueProviders[provider.provider_name].states.push(...provider.states)
			} else {
				uniqueProviders[provider.provider_name] = provider
			}
		})

		const insuranceProviders = Object.values(uniqueProviders)
		commit('setInsuranceProviders', insuranceProviders)
	},
	async setIsGift({ commit }, payload) {
		commit('setIsGift', payload)
	},
	async validatePromoCode({ commit }, requestData): Promise<PromoCodeData> {
		const { data }: { data: PromoCodeData } = await axios.post('/discounts/calculate', requestData)
		if (data.success) {
			commit('addAppliedPromoCode', data)
		} else {
			commit('removePromoCode', data?.code)
		}
		return data
	},
	async addWithdrawal({ commit }, withdrawalUuid: string) {
		commit('addWithdrawal', withdrawalUuid)
	},
	async setCart({ commit }, cartItems: SessionProduct[]) {
		try {
			commit('setCart', cartItems)
		} catch (e) {
			console.log('Error parsing items')
		}
	},
	addOrUpdateCartItem({ commit }, item: SessionProduct) {
		if (!item) {
			return
		}

		commit('addOrUpdateCartItem', item)
	},
	removeCartItem({ commit }, item: SessionProduct) {
		if (!item) {
			return
		}
		commit('removeCartItem', item)
	},
	decrementCartItemQuantity({ commit }, item: SessionProduct) {
		if (!item) {
			return
		}
		commit('decrementCartItemQuantity', item)
	},
	async saveCheckoutModel({ state }) {
		if (!state.checkoutModel?.account_details?.email) {
			return
		}

		return await axios.post('/checkout-intent/save-form', {
			form: {
				...state.checkoutModel,
				items: state.cart,
			},
		})
	},
	async sendSegmentEvents({ state, getters }, data: OrderAttributes) {
		const products: Product[] = getProductsForSegment(state.cart, state.products)

		const lastAppliedPromoCode = getters.lastAppliedPromoCode as PromoCodeData | null

		trackSegment({
			name: TrackingEvents.ORDER_COMPLETED.name,
			options: {
				order_id: data.po_number,
				total: data.is_insurance_order ? data.revenue : data.final_price,
				revenue: data.revenue,
				value: data.revenue,
				subtotal: data.is_insurance_order
					? data.revenue
					: lastAppliedPromoCode?.subtotal || data.final_price,
				currency: 'USD',
				shipping_amount: 0,
				coupon: lastAppliedPromoCode?.code || '',
				products,
				affiliation: data.affiliate,
				discount: data.invoice?.discount_savings,
				tax: data.invoice?.tax,
			},
		})

		segmentConversion(products, state.products, data.po_number)
	},
	async placeOrder({ state, commit, getters, dispatch }) {
		commit('checkoutLoading', true)
		try {
			const items = state.cart.map((item: SessionProduct) => {
				return {
					product_uuid: item.id,
					quantity: item.count,
					extradata: item?.extradata,
				}
			})

			const lastAppliedPromoCode = getters.lastAppliedPromoCode as PromoCodeData | null
			let discount: PromoCodeData | undefined

			if (lastAppliedPromoCode) {
				discount = {
					code: lastAppliedPromoCode.code,
					uuid: lastAppliedPromoCode.uuid,
					discounts: lastAppliedPromoCode.discounts,
					targets: lastAppliedPromoCode.targets,
				}
			}

			const data = {
				timezone: getTimezone(),
				...state.checkoutModel,
				account_details: {
					...state.checkoutModel.account_details,
					has_authorized_user: !!store.state.auth.user.has_authorized_user,
				},
				items,
				discount,
			}

			if (getters.shouldSkipPaymentInfo) {
				delete data.payment
				delete data.billing_address
			}

			if (getters.shouldSkipShippingDetails) {
				delete data.shipping_address
			}

			const response = await axios.post('/checkout', data)

			window.analytics?.identify(response.data.client.number)

			await dispatch('sendSegmentEvents', response.data)

			trackCheckoutGTM(response.data, getPurchasedProducts(response.data))
			postImpactConversionEvent(
				response.data.po_number,
				store.state.auth.isLoggedIn,
				discount,
				response.data.items,
				response.data.packages,
			)

			commit('setPlacedOrder', response.data)
			return response
		} catch (e) {
			throw e
		} finally {
			commit('checkoutLoading', false)
		}
	},
	async fetchIntent({ state, commit }) {
		if (!state.checkoutModel?.account_details?.email) {
			return
		}

		const {
			data: { intent, user },
		} = await axios.post('/checkout-intent', {
			form: {
				account_details: {
					email: state.checkoutModel.account_details.email,
				},
			},
		})

		commit('setIntent', intent)
		commit('setCheckoutUser', user)

		return { user, intent }
	},
	async checkInsuranceEligibility({ commit }, model: InsuranceEligibilityModel) {
		const insurance = await axios.post('/insurance/check', model)

		commit('setInsurance', insurance)

		return insurance
	},
	async initCheckoutModel({ commit, state, rootState }) {
		const checkoutBindings = (await axios.get('/checkout/bindings')) as CheckoutModel
		const clientDetails = rootState.auth.user.client?.attributes as ClientDetails

		if (!checkoutBindings?.billing_address?.country) {
			set(
				checkoutBindings,
				'billing_address.country',
				DEFAULT_CHECKOUT_MODEL.billing_address.country,
			)
		}

		const newCheckoutModel: CheckoutModel = merge(
			{},
			state.checkoutModel,
			{ account_details: { ...clientDetails } },
			checkoutBindings,
		)

		commit('setCheckoutModel', newCheckoutModel)
	},
}

const getters: GetterTree<State, RootState> = {
	lastAppliedPromoCode: (state: State): PromoCodeData | null => {
		if (state.appliedPromoCodes.length) {
			const validCodes = state.appliedPromoCodes.filter(p => p.success)
			return validCodes[validCodes.length - 1]
		}
		return null
	},
	cartCount: (state: State): number => {
		return state.cart.reduce((acc: number, item: SessionProduct) => item.count + acc, 0)
	},
	supplementPackages: (state): Product[] => {
		const supplementProductUuids = [PackagesUuid.MALE_FERTILITY_SUPPLEMENT] as string[]
		return state.products.filter(p => supplementProductUuids.includes(p.uuid))
	},
	packages: (state): Product[] => {
		return state.products.filter(p => p.products)
	},
	insuranceablePackages: (state, getters) => {
		return getters.packages.filter((p: Product) => p.insuranceable)
	},
	isMFSOnly: (state: State): boolean => {
		return (
			state.cart.length === 1 && state.cart[0].id === ProductsUuid.MALE_FERTILITY_SUPPLEMENT_UUID
		)
	},
	hasInsuranceCoverage: (state: State): boolean => {
		return !!state.checkoutModel?.insurance?.application_uuid
	},
	hasFullDiscount: (state: State, getters): boolean => {
		const discount: PromoCodeData = getters.lastAppliedPromoCode

		if (!discount) {
			return false
		}

		if (!discount.success) {
			return false
		}

		return Number.parseFloat(discount.total ?? '') === 0
	},
	defaultTotal: (state: State, getters): number => {
		const sum = getters.cartItemsExtended.reduce((acc: number, item: CartItemExtended) => {
			return acc + (item.count || 0) * item.price
		}, 0)

		return sum
	},
	totals: (state: State, getters): CheckoutTotals => {
		let discounts = +(getters.lastAppliedPromoCode?.discounts || 0)
		let total = getters.defaultTotal - discounts
		if (total < 0) {
			total = 0
		}
		let insuranceCoverage = 0
		if (getters.hasInsuranceCoverage) {
			insuranceCoverage = total
			total = 0
		}
		return {
			discounts: -discounts,
			subtotal: +(getters.lastAppliedPromoCode?.subtotal || getters.defaultTotal),
			insuranceCoverage,
			total,
		}
	},
	cartItemsExtended: (state: State): CartItemExtended[] => {
		return state.cart.map(item => {
			const product = state.products.find(p => p.uuid === item.id)

			return {
				...product,
				...item,
			} as CartItemExtended
		})
	},
	hasCryoItems: (state: State, getters): boolean => {
		return getters.cartItemsExtended.some(
			(item: CartItemExtended) => item.is_cryogenic || item.products?.some(p => p.is_cryogenic),
		)
	},
	hasOnlySti: (state: State, getters): boolean => {
		return !getters.cartItemsExtended.some(
			(product: Product) => product.uuid !== ProductsUuid.STI_UUID,
		)
	},
	hasOnlyFertilityConsultation: (state: State, getters): boolean => {
		return !getters.cartItemsExtended.some(
			(item: Product) => item.uuid !== PackagesUuid.FERTILITY_CONSULTATION_UUID,
		)
	},
	hasSubscriptionItems: (state: State, getters): boolean => {
		return getters.cartItemsExtended.some(
			(item: CartItemExtended) =>
				item.with_subscription || item.products?.some(p => p.with_subscription),
		)
	},
	hasKitItems: (state: State, getters): boolean => {
		return getters.cartItemsExtended.some(
			(item: CartItemExtended) =>
				item.uuid === ProductsUuid.ANALYSES_UUID ||
				item.products?.some(p => p.uuid === ProductsUuid.ANALYSES_UUID),
		)
	},
	hasStandaloneSti: (state: State, getters): boolean => {
		return getters.cartItemsExtended.some(
			(item: Product) =>
				item.uuid === ProductsUuid.STI_UUID ||
				item.products?.every(p => p.uuid === ProductsUuid.STI_UUID),
		)
	},
	containsStiProduct: (state: State, getters): boolean => {
		return getters.cartItemsExtended.some(
			(item: Product) =>
				item.uuid === ProductsUuid.STI_UUID ||
				item.products?.some(p => p.uuid === ProductsUuid.STI_UUID),
		)
	},
	shouldSkipPaymentInfo: (state: State, getters): boolean => {
		const lastAppliedPromoCode: PromoCodeData = getters.lastAppliedPromoCode

		if (lastAppliedPromoCode?.alwaysSkipPaymentInfo || lastAppliedPromoCode?.testable) {
			return true
		}

		if (
			!getters.hasSubscriptionItems &&
			(getters.hasFullDiscount || getters.hasInsuranceCoverage)
		) {
			return true
		}
		if (getters.hasSubscriptionItems && (getters.hasFullDiscount || getters.hasInsuranceCoverage)) {
			return false
		}

		return getters.hasFullDiscount || getters.hasInsuranceCoverage
	},
	shouldSkipShippingDetails: (state: State, getters, rootState: RootState): boolean => {
		const skipForQueryParams = ['withdrawalItemUuid']
		const isFamilyConsultationOnly = getters.cartItemsExtended.some(
			(item: CartItemExtended) =>
				item.uuid === ProductsUuid.FERTILITY_CONSULTATION_UUID ||
				item.products?.some(p => p.uuid === ProductsUuid.FERTILITY_CONSULTATION_UUID),
		)

		if (isFamilyConsultationOnly && getters.cartItemsExtended.length === 1) {
			return true
		}

		return (
			Object.keys(rootState.route.query).findIndex(param => skipForQueryParams.includes(param)) !==
			-1
		)
	},
	productByUuid:
		(state: State) =>
		(uuid: string): Product | undefined => {
			return state.products.find(p => p.uuid === uuid)
		},
}

const module: Module<State, RootState> = {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}

export default module

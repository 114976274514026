import { ItemAttributes, PackageAttributes } from '@/modules/items/types/itemTypes'
import { PromoCodeData } from '@/modules/checkout/types/checkoutTypes'
import { isProduction } from '@/modules/common/utils/envUtils'

const LEGACY_IMPACT_EVENT_ID = 27982

function getUniversalTrackingTag() {
	try {
		//this code is Impact's, not mine; they require it as written
		// @ts-ignore
		;(function (a, b, c, d, e, f, g) {
			e['ire_o'] = c
			e[c] =
				e[c] ||
				function () {
					;(e[c].a = e[c].a || []).push(arguments)
				}
			f = d.createElement(b)
			g = d.getElementsByTagName(b)[0]
			f.async = 1
			f.src = a
			g.parentNode.insertBefore(f, g)
		})(
			'https://utt.impactcdn.com/A3094510-8249-40a6-81c6-3bdd13de9eba1.js',
			'script',
			'ire',
			document,
			window,
		)
	} catch (e) {}
}

export async function postImpactIdentifyEvent() {
	if (!isProduction()) {
		return
	}

	getUniversalTrackingTag()

	window?.ire('identify', { customerId: '', customerEmail: '' })
}

export async function postImpactConversionEvent(
	orderNumber: number,
	isUserAuthenticated: boolean,
	discount: PromoCodeData | null | undefined,
	items: Array<ItemAttributes>,
	orderPackages: Array<PackageAttributes & { pivot: { quantity: number } }>,
) {
	if (!isProduction()) {
		return
	}

	getUniversalTrackingTag()

	let itemsImpactFormat = []

	for (const item of items) {
		if (item.package) {
			continue
		}

		let itemImpactFormat = {
			subTotal: item.price,
			category: '',
			sku: item.sku,
			quantity: item.quantity,
			name: item.title,
		}

		itemsImpactFormat.push(itemImpactFormat)
	}

	for (const orderPackage of orderPackages) {
		let itemImpactFormat = {
			subTotal: orderPackage.price,
			category: '',
			sku: orderPackage.uuid,
			quantity: orderPackage.pivot.quantity,
			name: orderPackage.name,
		}

		itemsImpactFormat.push(itemImpactFormat)
	}

	window?.ire(
		'trackConversion',
		LEGACY_IMPACT_EVENT_ID,
		{
			orderId: orderNumber,
			customerStatus: isUserAuthenticated ? 'Returning' : 'New',
			currencyCode: 'USD',
			orderPromoCode: discount?.code,
			orderDiscount: discount?.discounts,
			items: itemsImpactFormat,
		},
		{
			verifySiteDefinitionMatch: true,
		},
	)
}

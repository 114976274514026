import { App } from 'vue'
import { ClientType, ParentUserType, UserType } from '@/modules/auth/types/authTypes'

export default {
	install(Vue: App) {
		Vue.mixin({
			computed: {
				$user(): UserType {
					return this.$store.state.auth.user
				},
				$parentUser(): ParentUserType {
					return this.$user.parent_user
				},
				$client(): ClientType {
					return this.$user?.client?.attributes
				},
				$isLoggedIn(): boolean {
					return this.$store.state.auth?.isLoggedIn
				},
			},
		})
	},
}

import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { RootState } from '@/store'
import { AxiosList, RequestParams } from '@/modules/common/apiConfig'
import axios from 'axios'
import {
	AnalysisWithoutAddon,
	CryogenicItem,
	CryogenicsAttributes,
	CryoMatch,
} from '@/modules/cryogenics/types/cryogenicTypes'
import { Item, ItemAttributes } from '@/modules/items/types/itemTypes'

export type State = {
	cryogenicsLoading: boolean
	allVialsWithdrawn: boolean
	allVialsDestroyed: boolean
	cryoShippingProductUuid: string
	hasCryogenic: boolean
	items: Item[]
	unusedWithdrawalCryoshipping: ItemAttributes | null
	analysisWithoutAddon: AnalysisWithoutAddon[]
	analysisWithoutAddonLoading: boolean
	availableCryogenicItems: CryogenicItem[]
	availableCryogenicItemsLoading: boolean
	cryoMatches: CryoMatch[]
	newCryoMatch: CryoMatch | null
	autoMatchedCryo: boolean
}

export const state = (): State => ({
	cryogenicsLoading: false,
	allVialsWithdrawn: false,
	allVialsDestroyed: false,
	cryoShippingProductUuid: '',
	hasCryogenic: false,
	items: [],
	unusedWithdrawalCryoshipping: null,
	analysisWithoutAddon: [],
	analysisWithoutAddonLoading: false,
	availableCryogenicItems: [],
	availableCryogenicItemsLoading: false,
	cryoMatches: [],
	newCryoMatch: null,
	autoMatchedCryo: false,
})

const testingPanelMutations = {
	setAllVialsDestroyed(state: State, value: boolean) {
		state.allVialsDestroyed = value
	},
	setHasCryogenic(state: State, value: boolean) {
		state.hasCryogenic = value
	},
	setAllVialsWithdrawn(state: State, value: boolean) {
		state.allVialsWithdrawn = value
	},
	setItems(state: State, value: Item[]) {
		state.items = value
	},
}

const mutations: MutationTree<State> = {
	setCryogenicsData(state: State, data: CryogenicsAttributes) {
		const {
			are_all_vials_withdrawn,
			are_all_vials_destroyed,
			cryo_shipping_product_uuid,
			has_cryogenic,
			unused_withdrawal_cryoshipping,
			items,
		} = data

		state.allVialsWithdrawn = are_all_vials_withdrawn
		state.allVialsDestroyed = are_all_vials_destroyed
		state.cryoShippingProductUuid = cryo_shipping_product_uuid
		state.hasCryogenic = has_cryogenic
		state.unusedWithdrawalCryoshipping = unused_withdrawal_cryoshipping
		state.items = items
	},
	setCryogenicsLoading(state: State, loading: boolean) {
		state.cryogenicsLoading = loading
	},
	setAnalysisWithoutAddon(state: State, value: AnalysisWithoutAddon[]) {
		state.analysisWithoutAddon = value
	},
	setAnalysisWithoutAddonLoading(state: State, value: boolean) {
		state.analysisWithoutAddonLoading = value
	},
	setAvailableCryogenicItems(state: State, value: CryogenicItem[]) {
		state.availableCryogenicItems = value
	},
	setAvailableCryogenicItemsLoading(state: State, value: boolean) {
		state.availableCryogenicItemsLoading = value
	},
	setNewCryoMatch(state: State, value: CryoMatch | null) {
		state.newCryoMatch = value
	},
	setNewCryoMatchValue(
		state: State,
		{ key, value }: { key: string; value: AnalysisWithoutAddon | CryogenicItem },
	) {
		state.newCryoMatch = {
			...state.newCryoMatch,
			[key]: value,
		} as CryoMatch
	},
	addCryoMatch(state: State, cryoMatch: CryoMatch) {
		if (!cryoMatch) {
			return
		}
		state.cryoMatches.push(cryoMatch)
	},
	setAutoMatchedCryo(state: State, value: boolean) {
		state.autoMatchedCryo = value
	},
	setCryoMatches(state: State, value: CryoMatch[]) {
		state.cryoMatches = value
	},
	revertLastAddedCryoMatch(state: State) {
		state.newCryoMatch = state.cryoMatches.pop() as CryoMatch
	},
	...testingPanelMutations,
}

const actions: ActionTree<State, RootState> = {
	async getCryogenics(
		{ commit, state },
		params = {} as RequestParams,
	): Promise<CryogenicsAttributes | void> {
		try {
			commit('setCryogenicsLoading', true)
			const cryogenicData: { data: CryogenicsAttributes } = await axios.get('/cryogenics', {
				params,
			})

			let { data } = cryogenicData

			commit('setCryogenicsData', data)

			return data
		} catch (err: any) {
			if (err.handled) {
				return
			}
			throw err
		} finally {
			commit('setCryogenicsLoading', false)
		}
	},
	async getAnalysisWithoutAddon({ commit, state }) {
		try {
			commit('setAnalysisWithoutAddonLoading', true)
			const params = {
				['filter[analysis_without_cryo_addon]']: true,
				related: 'resultDocuments[post_thaw_motility|total_motile_sperm|normal|collected_at]',
			}

			const itemsData: AxiosList<ItemAttributes> = await axios.get('/restify/items', {
				params,
			})

			let { data } = itemsData

			commit('setAnalysisWithoutAddon', data)

			return data
		} catch (err: any) {
			if (err.handled) {
				return
			}
			throw err
		} finally {
			commit('setAnalysisWithoutAddonLoading', false)
		}
	},
	async getAvailableCryogenicItems({ commit, state }) {
		try {
			commit('setAvailableCryogenicItemsLoading', true)
			const params = {
				['filter[available_cryogenic_items]']: true,
				related: 'product[uuid|title]',
			}

			const itemsData: AxiosList<ItemAttributes> = await axios.get('/restify/items', {
				params,
			})

			let { data } = itemsData

			commit(
				'setAvailableCryogenicItems',
				data.map(
					(item, i) =>
						({
							...item,
							index: i + 1,
						} as CryogenicItem),
				),
			)

			return data
		} catch (err: any) {
			if (err.handled) {
				return
			}
			throw err
		} finally {
			commit('setAvailableCryogenicItemsLoading', false)
		}
	},
	confirmCryogenicMatch({ state, commit, getters }) {
		commit('addCryoMatch', state.newCryoMatch)
		commit('setNewCryoMatch', null)
	},
	async submitCryoMatching({ state, commit }) {
		try {
			const data = state.cryoMatches.map(match => ({
				cryogenic_uuid: match.cryogenicItem.attributes.uuid,
				analysis_uuid: match.analysis.attributes.uuid,
			}))

			const result = await axios.post(
				'/restify/items/actions?action=match-cryogenic-with-analyses',
				{ data },
			)

			commit('setCryoMatches', [])
			return result
		} catch (err: any) {
			if (err.handled) {
				return
			}
			throw err
		}
	},
}

const getters: GetterTree<State, RootState> = {
	unmatchedAvailableCryogenicItems: (state: State): CryogenicItem[] => {
		return state.availableCryogenicItems.filter(
			cryogenicItem =>
				!state.cryoMatches.some(cryoMatch => {
					return cryoMatch.cryogenicItem.attributes?.uuid === cryogenicItem.attributes?.uuid
				}),
		)
	},
	unmatchedAnalysisWithoutAddon: (state: State): AnalysisWithoutAddon[] => {
		return state.analysisWithoutAddon.filter(
			analysis =>
				!state.cryoMatches.some(cryoMatch => {
					return cryoMatch.analysis.attributes?.uuid === analysis.attributes?.uuid
				}),
		)
	},
}

const module: Module<State, RootState> = {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}

export default module

import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { RootState } from '@/store'
import { SplitFactory } from '@splitsoftware/splitio'

const SPLIT_IO_AUTHORIZATION_KEY: string | undefined = import.meta.env
	.VITE_SPLIT_IO_AUTHORIZATION_KEY as string

export type State = {
	splitIOClient: SplitIO.IClient | null
}

export const state = (): State => ({
	splitIOClient: null,
})

const mutations: MutationTree<State> = {
	setClient(state: State, client: SplitIO.IClient) {
		state.splitIOClient = client
	},
	destroyClient(state: State) {
		state.splitIOClient?.destroy()
	},
}

const actions: ActionTree<State, RootState> = {
	setupClient({ commit, rootState, state }) {
		const userUuid = rootState.auth?.user?.uuid
		/**
		 * This uses a vanilla JS implementation b/c we cannot
		 * guarantee the existence of Vue-Router when in the
		 * marketing site.
		 */
		const params = new Proxy(new URLSearchParams(window.location.search), {
			// @ts-ignore
			get: (searchParams, prop) => searchParams.get(prop),
		})
		// @ts-ignore
		let key = params['split_io_key']

		if (!key && userUuid) {
			key = userUuid
		}

		if (!key && !userUuid) {
			key = 'no_key'
		}

		const factory: SplitIO.ISDK = SplitFactory({
			core: {
				authorizationKey: SPLIT_IO_AUTHORIZATION_KEY,
				key: key,
			},
		})
		commit('setClient', factory.client())
	},
	setupAndGetClient({ state, dispatch }) {
		if (!state.splitIOClient) {
			dispatch('setupClient')
		}
		return state.splitIOClient
	},
	destroyClient({ commit }) {
		commit('destroyClient')
	},
	async waitForClientSdkReady({ commit, dispatch, state }, { retries = 5 }) {
		/**
		 * Retry is required due to limitation with the Split.io SDK
		 * We should be using the client.on() function but the callback
		 * breaks reactive functionality. This function acts in the same way
		 * ensuring the client SDK is cached before attempting to access.
		 **/
		const delay = async (ms: number) => {
			return new Promise(res => setTimeout(res, ms))
		}
		const client = state.splitIOClient

		if (client === null) {
			return false
		}
		if (retries > 0) {
			try {
				await client.ready()
				return true
			} catch (e) {
				await delay(500)
				await dispatch('waitForClientSdkReady', retries - 1)
			}
		}
		return false
	},
}

const getters: GetterTree<State, RootState> = {
	splitIOClient(state: State) {
		return state.splitIOClient
	},
}

const module: Module<State, RootState> = {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}

export default module
